import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-future-term',
  templateUrl: './future-term.page.html',
  styleUrls: ['./future-term.page.scss'],
})
export class FutureTermPage implements OnInit {

  futureTermValidation:string;
  backToFutureHead:string;
  backToFutureTail:string;
  constructor(private router: Router,
    private location: Location,
    private restApi:RestApiService) {

  }

  ngOnInit() {
    this.restApi.getProperty("futureTermValidation").subscribe(data => {
      this.futureTermValidation = data.propertyValue;   
  })

  this.restApi.getProperty("backToFutureHead").subscribe(data => {
    this.backToFutureHead = data.propertyValue;   
  })

  this.restApi.getProperty("backToFutureTail").subscribe(data => {
    this.backToFutureTail = data.propertyValue;   
  })

}

  tomorrowsTerm() {
    this.location.back();
  }


}
