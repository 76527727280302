import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CategoryiconsPageRoutingModule } from './categoryicons-routing.module';

import { CategoryiconsPage } from './categoryicons.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CategoryiconsPageRoutingModule
  ],
  declarations: [CategoryiconsPage],
  exports: [CategoryiconsPage]
})
export class CategoryiconsPageModule { }
