import { Component, Renderer2, ViewChild } from '@angular/core';
import { Platform, AlertController, IonRouterOutlet } from '@ionic/angular';
import { Location } from '@angular/common';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  panelOpenState = false;

  constructor(private renderer: Renderer2,
    private platform: Platform,
    private alertController: AlertController,
    private location: Location,
    private storage: Storage,) {
    this.initilizeApp();
    this.backButtonEvent();
    this.storage.create();

  }

  initilizeApp() {
    this.platform.ready().then(() => {

    })
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      if (this.location.isCurrentPathEqualTo('/home/dashboard')) {
        this.backButtonAlert();
      } else {
        this.location.back();
      }
    });
  }

  async backButtonAlert() {
    const alert = await this.alertController.create({
      message: 'Do you want to close the app?',
      buttons: [{
        text: 'Cancel',
        role: 'cancel'
      }, {
        text: 'Close App',
        role: 'exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });
    await alert.present();
  }

  onToggleColorTheme(event) {
    if (event.detail.checked) {
      this.renderer.setAttribute(document.body, 'color-theme', 'dark');

    } else {

      this.renderer.setAttribute(document.body, 'color-theme', 'light');

    }
  }
}
