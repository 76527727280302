import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DefinitionPageRoutingModule } from './definition-routing.module';

import { DefinitionPage } from './definition.page';
import { MatExpansionModule } from '@angular/material/expansion';
import { TopbannerPageModule } from '../../shared/components/topbanner/topbanner.module';
import { CategoryiconsPageModule } from 'src/app/shared/components/categoryicons/categoryicons.module';
import { FutureTermPageModule } from '../future-term/future-term.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DefinitionPageRoutingModule,
    MatExpansionModule,
    TopbannerPageModule,
    CategoryiconsPageModule,
    FutureTermPageModule
  ],
  declarations: [DefinitionPage]
})
export class DefinitionPageModule { }
