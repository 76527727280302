import { Component, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-categoryicons',
  templateUrl: './categoryicons.page.html',
  styleUrls: ['./categoryicons.page.scss'],
})
export class CategoryiconsPage implements OnInit {
  data: any = [];

  constructor(private restApi: RestApiService) { }

  ngOnInit() {
    this.initilizaData();
  }

  initilizaData() {
    this.restApi.getAllCategories().subscribe(data => {
      this.data = data;
    })
  }

  imageClicked(id: number) {
    console.log("image clicked " + id);
  }

}
