import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-topbanner',
  templateUrl: './topbanner.page.html',
  styleUrls: ['./topbanner.page.scss'],
})
export class TopbannerPage implements OnInit {

  title: any;
  pathName: any;
  abc: string;
  abcd: string;
  id: any;

  todayDate = Date.now();
  presentDate = Date.now();

  ettLogoUrl:string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private restApi:RestApiService) {
    this.pathName = this.router.url;
    this.abc = this.pathName;
    this.abcd = this.abc.slice();
    this.id = this.route.snapshot.paramMap.get('name');

    switch (this.pathName) {
      case "/home/dashboard": {
        this.title = "DefineTestTerms"
        break;
      }

      case "/home/search/" + this.id: {
        this.title = "DefineTestTerms"
        break;
      }

      case "/home/terms": {
        this.title = "My Terms"
        break;
      }

      case "/home/definition/" + this.id: {
        this.title = "Definition"
        break;
      }

      case "/home/todayTerm": {
        this.title = "Today's Term"
        break;
      }

      case "/home/randomdefinition": {
        this.title = "Random  Term"
        break;
      }

      case "/home/more": {
        this.title = "Settings"
        break;
      }

      case "/home/categories": {
        this.title = "Categories"
        break;
      }

      case "/home/future-term": {
        this.title = "Future Term"
        break;
      }

      case "/home/about-edtech": {
        this.title = "DefineTestTerms";
        break;
      }

      case "/home/about-esp": {
        this.title = "ESP";
        break;
      }

      case "/home/contactus": {
        this.title = "ContactUs";
        break;
      }

      case "/home/sponsor": {
        this.title = "Sponsor";
        break;
      }









      default:
        break;
    }

  }



  ngOnInit() {}
}
