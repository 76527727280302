import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';


const MY_TERMS_KEY = "MyTerms";

@Injectable({
  providedIn: 'root'
})
export class MyTermStorageService {

  constructor(private storage: Storage) {

  }

  addData(term: string) {
    //this.storage.clear();
    return this.storage.get(MY_TERMS_KEY).then((myTerms: string[]) => {
      if (myTerms) {
        myTerms.push(term);
        let myTermsSet: Set<string> = new Set();
        myTerms.forEach(item => myTermsSet.add(item));
        let myTermsArray: string[] = [];
        myTermsSet.forEach(item => myTermsArray.push(item));
        return this.storage.set(MY_TERMS_KEY, myTermsArray);
      } else {
        return this.storage.set(MY_TERMS_KEY, [term]);
      }
    });
  }

  deleteData(term: string) {
    return this.storage.get(MY_TERMS_KEY).then((myTerms: string[]) => {
      if (myTerms) {
        let myTermsSet: Set<string> = new Set();
        myTerms.forEach(item => myTermsSet.add(item));
        let myTermsArray: string[] = [];
        myTermsSet.forEach(item => myTermsArray.push(item));
        myTermsArray.forEach((element, index) => {
          if (element == term) myTermsArray.splice(index, 1);
        });
        return this.storage.set(MY_TERMS_KEY, myTermsArray);
      }
    });
  }



  getData(): Promise<string[]> {
    let load = 0;
    if (load < load) {
      window.location.reload();
      load++;
    }
    return this.storage.get(MY_TERMS_KEY);
  }
}
