import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  api: string = "https://definetestterms.com:8443/api/v1";
  //api: string = "https://localhost:8443/api/v1";
  token: string;

  ettLogoUrl:string;
  constructor(private http: HttpClient
  ) {


  }

  getAllTerms(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders({ Authorization: 'Basic ' + "YWRtaW46ZDVlN2M3OWQtMWM1Zi00YWRmLTk5YzktNmYxNjhjZmJhOTEy" });
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/',{headers})
  }

  getRandomTermId(): Observable<number> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/random',{headers})
  }

  getRandomTermByDate(date: string): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/datetoday/termdate/' + date,{headers})
  }

  getAllTermsBySearch(textValue: string): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/search?text=' + textValue,{headers})
  }

  getAllTermsBySearchTermText(textValue: string): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/searchtermtext?text=' + textValue,{headers})
  }



  getOneTermBySearch(textValue: string): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/new/search?name=' + textValue,{headers})
  }

  //new api
  getTermSearchByCategory(textValue: string,id:number): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/searchByCategory?term=' + textValue +"&categoryId="+id,{headers})
  }

  getAllTermsByCategoryIdAndTerm(Id: number, text: String): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/new/' + Id + '/search?text=' + text,{headers})

  }


  getAllTermsByCategory(Id: number,offset:number,limit:number): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/category/' + Id + "?offset="+offset+"&limit="+limit,{headers})
  }

//get all terms by category id and search term in search box from UI
  retrieveAllByCategoryIdAndSearchTerm(Id: number, text: String,offset:number,limit:number): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/' + Id + '/search?text=' + text+ "&offset="+offset+"&limit="+limit,{headers})
  }

  deletTermById(Id: number): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete<any>(this.api + '/terms/' + Id,{headers})
  }

  getTermById(Id: number): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/terms/' + Id,{headers})
  }


  getAllCategories(): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders(); 
    headers = headers.append('Content-Type', 'application/json');

    return this.http.get<any>(this.api + '/categories',{headers})
  }

  

  createCategory(requestformData): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(this.api + '/categories/', requestformData,{headers})
  }

  editCategory(requestEditformData, id): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.put<any>(this.api + '/categories/' + id, requestEditformData,{headers})
  }

  deleteCategory(id: number): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.delete<any>(this.api + '/categories/' + id,{headers})
  }

  getCategory(id: number): Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/categories/' + id,{headers})
  }

  getProperty(property:string):Observable<any> {
    let headers: HttpHeaders = this.getAuthorizationHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(this.api + '/property/' + property, { headers })
  }

  getAuthorizationHeaders(): HttpHeaders{
    return new HttpHeaders({ Authorization: 'Basic ' + "YWRtaW46ZDVlN2M3OWQtMWM1Zi00YWRmLTk5YzktNmYxNjhjZmJhOTEy" });
  }
  
  getEttLogoUrl(): string{
    
    this.getProperty("ETTLogo").subscribe(data => {
      this.ettLogoUrl = data.propertyValue;
      console.log("ettlogo:"+this.ettLogoUrl);
      return this.ettLogoUrl;
    })
    return this.ettLogoUrl;
  }

}
