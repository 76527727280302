import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DefinitionPageModule } from './pages/definition/definition.module';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'more',
    loadChildren: () => import('./pages/more/more.module').then(m => m.MorePageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'todaysterm',
    //loadChildren: () => import('./pages/todaysterm/todaysterm.module').then(m => m.TodaystermPageModule)
    component: DefinitionPageModule
  },
  {
    path: 'randomterm',
    //loadChildren: () => import('./pages/definition/definition.module').then(m => m.DefinitionPageModule)
    component: DefinitionPageModule
  },
  {
    path: 'categoryicons',
    loadChildren: () => import('./shared/components/categoryicons/categoryicons.module').then(m => m.CategoryiconsPageModule)
  },
  {
    path: 'future-term',
    loadChildren: () => import('./pages/future-term/future-term.module').then(m => m.FutureTermPageModule)
  },
  {
    path: 'about-definetestterms',
    loadChildren: () => import('./pages/about-ed-tech-terms/about-ed-tech-terms.module').then( m => m.AboutEdTechTermsPageModule)
  },
  {
    path: 'contactus',
    loadChildren: () => import('./pages/contactus/contactus.module').then( m => m.ContactusPageModule)
  },  {
    path: 'sponsor',
    loadChildren: () => import('./pages/sponsor/sponsor.module').then( m => m.SponsorPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
